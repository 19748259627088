import { BaseService } from "../base.service";

class SequenceService extends BaseService {
  async createPath(body: { titles: string[] }) {
    const resp = await this.client.post("/content/sequence/path/create", body);

    return resp?.data;
  }

  async createModule(body: { pathId: number; titles: string[] }) {
    const resp = await this.client.post(
      "/content/sequence/module/create",
      body
    );

    return resp?.data;
  }

  async getPaths(body?: {searchText: string}) {
    const resp = await this.client.post("/content/sequence/path/get", body);

    return resp?.data;
  }

  async getModules(body?: {pathId: number, searchText?: string}) {
    const resp = await this.client.post("/content/sequence/module/get", body || {});

    return resp?.data;
  }
}

export const sequenceService = new SequenceService();
