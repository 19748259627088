"use client";

import { Icons } from "@/components/icons/CustomIcons";
import { Button } from "@/components/third-party/shadcn/button";
import { Input } from "@/components/third-party/shadcn/input";
import { sequenceService } from "@/service/content/sequence.service";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Box,
  Flex,
  Avatar,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";

interface AddSequence {
  title: string;
  type: "PATH" | "MODULE" | "STREAM";
}

export const AddSequenceModal = ({
  pathId,
  type,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [selectedItems, setSelectedItems] = useState([] as AddSequence[]);
  const [focussedItem, setfocussedItem] = useState(null);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState("");
  const toast = useToast();

  const handleAddItem = () => {
    if (!searchText || searchText.length < 5) return;

    setSelectedItems((prevItems) => {
      return [...prevItems, { title: searchText, type } as AddSequence];
    });

    setSearchText("");
  };

  const handleClose = () => {
    setSelectedItems([]);
    setSearchText("");
    onClose();
  };

  const handleDelete = (title) => {
    setSelectedItems((prevItems) => {
      return prevItems.filter((user) => user.title !== title);
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      titles: selectedItems.map((item) => item.title),
    };

    const worker =
      type === "PATH"
        ? sequenceService.createPath(payload)
        : sequenceService.createModule({ ...payload, pathId });

    await worker
      .then(() => {
        toast({
          title: `${type}'s added`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        handleClose();
      })
      .catch(() => {
        toast({
          title: "Error adding items",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }).finally(() => setLoading(false));
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody px="12px">
          <Box fontSize="18px" mb="10px" fontWeight="bold">
            Add a {type.toLowerCase()}
          </Box>
          <Input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={"Add a title and press 'Enter'"}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAddItem();
              }
            }}
            name={""}
            title={""}
          />
          {searchText && searchText.length > 5 && (
            <Box fontSize="13px" color="gray" mt="10px">
              Press 'Enter' to add item
            </Box>
          )}

          <Box
            gap="8px"
            overflowY="auto"
            maxHeight="185px"
            zIndex={9999}
            css={{
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#A0AEC0",
                borderRadius: "4px",
                height: "40px",
              },
            }}
          >
            {!!selectedItems?.length &&
              selectedItems.map((item) => (
                <Box
                  key={item.title}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  py={2}
                  mt={2}
                  paddingLeft={1}
                  borderRadius={10}
                  onClick={handleAddItem}
                  onMouseEnter={() => setfocussedItem(item)}
                  onMouseLeave={() => setfocussedItem(null)}
                >
                  <Flex alignItems="center" gap="4px" w="100%">
                    <Flex
                      flexDir="row"
                      alignItems="center"
                      justifyContent="space-between"
                      w="100%"
                      py={0}
                    >
                      <Box fontSize="14px" fontWeight={600} color="#140D26">
                        {item.title}
                      </Box>
                      <Flex flexDir="row" alignItems="center" gap={2} py={0}>
                        <Box
                          cursor="pointer"
                          onClick={() => handleDelete(item.title)}
                        >
                          <Icons.Trash
                            color={
                              focussedItem?.title === item.title
                                ? "#BB2B25"
                                : undefined
                            }
                          />
                        </Box>
                      </Flex>
                    </Flex>
                  </Flex>
                </Box>
              ))}
          </Box>
        </ModalBody>
        <ModalFooter mt="20px" borderTop="1px solid #EFF2F5" px="12px">
          <Flex flexDir="row" gap="8px" w="100%" justifyContent="end">
            <Button
              variant="outline"
              className="w-[100px]"
              onClick={handleClose}
            >
              <Text m="auto" fontSize="15px">
                Cancel
              </Text>
            </Button>
            <Button
              disabled={!selectedItems?.length || loading}
              className="w-[100px]"
              onClick={handleSubmit}
            >
              <Text m="auto" fontSize="15px">
                Add {type.toLowerCase()}
              </Text>
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
