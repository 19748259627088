import { useEffect } from 'react';

const useClickOutside = (ref, callback, ignoreRefs = [], disable = true) => {
  useEffect(() => {
    if (disable) return;

    const handleClickOutside = (event) => {
      const isClickInsideIgnoredRef = ignoreRefs.some(ignoreRef => 
        ignoreRef.current && ignoreRef.current.contains(event.target)
      );

      if (
        !isClickInsideIgnoredRef &&
        ref.current &&
        !ref.current.contains(event.target) 
      ) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback, ignoreRefs, disable]);
};

export default useClickOutside;
