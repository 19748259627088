"use client";

import { useEffect, useState } from "react";
import { SequenceSearchBar } from "./parts/SequenceSearchBar";

export const QoosSequenceGenerator = ({ onSequenceChange }) => {
  const [path, setPath] = useState(null);
  const [module, setModule] = useState(null);
  const [stream, setStream] = useState(null);

  const handleSelect = (type, item) => {
    switch (type) {
      case "PATH":
        setPath(item);
        break;
      case "MODULE":
        setModule(item);
        break;
    }
  };

  useEffect(() => {
    onSequenceChange({ path, module });
  }, [path, module]);

  return (
    <div className="flex flex-col gap-4">
      <SequenceSearchBar
        type="PATH"
        onClick={handleSelect}
        onReset={() => {
          setPath(null);
          setModule(null);
        }}
      />
      
      <SequenceSearchBar
        type="MODULE"
        onClick={handleSelect}
        pathId={path?.id}
        disabled={!path?.id}
      />
    </div>
  );
};
