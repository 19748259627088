"use client";

import { Icons } from "@/components/icons";
import useClickOutside from "@/lib/hooks/click-outside.hook";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { AddSequenceModal } from "./AddSequenceModal";
import { sequenceService } from "@/service/content/sequence.service";

interface Props {
  pathId?: number;
  type: "PATH" | "MODULE" | "STREAM";
  onClick?: (type, item) => void;
  onReset?: () => void;
  disabled?: boolean;
}

export const SequenceSearchBar = ({
  pathId,
  type,
  onClick,
  onReset,
  disabled,
}: Props) => {
  const [searchText, setSearchText] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [resultsOpen, setResultsOpen] = useState(false);
  const [addSequenceOpen, setAddSequenceOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const inputRef = useRef(null);
  const resultRef = useRef(null);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchResponse(searchText);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const [searchResults, setSearchResults] = useState(
    [] as { id?: string; title?: string; qoos: any[] }[]
  );

  const fetchResponse = async (search = searchText) => {
    setLoading(true);

    const payload = {
      searchText: search,
    };

    const worker =
      type === "PATH"
        ? sequenceService.getPaths(payload)
        : sequenceService.getModules({ pathId, ...payload });

    try {
      await worker.then((res) => {
        setSearchResults(res);
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!searchText?.length) return;

    setSearchText(null);
  };

  const handleClick = (item) => {
    console.log("item", item);
    setSearchText(item.title);
    setSelectedItem(item);
    setIsFocused(false);
    setResultsOpen(false);

    if (onClick) onClick(type, item);
  };

  const handleClearInput = () => {
    setSearchText("");
    setSelectedItem(null);
    setIsFocused(true);
    if (onReset) onReset();
    inputRef.current?.focus();
  };

  useClickOutside(
    inputRef,
    () => {
      setIsFocused(false);
      setResultsOpen(false);
    },
    [resultRef],
    false
  );

  useEffect(() => {
    if (isFocused) {
      setResultsOpen(true);
    }
    fetchResponse();
  }, [isFocused]);

  return (
    <>
      <div className="text-[16px] font-semibold">
        Select {type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}
      </div>
      <Flex pos="relative" flexDir="row" gap="16px" w="100%" pl="1px">
        <InputGroup w="100%" pos="relative">
          {!isFocused && selectedItem ? (
            <Flex
              align="center"
              w="100%"
              h="45px"
              borderWidth="1px"
              borderRadius="8px"
              borderColor="lightGray"
              boxShadow="0px 2px 4px 0px #0000000D"
              pl="2"
              pr="10"
              onClick={() => setIsFocused(true)}
            >
              <Tag
                size="lg"
                // borderRadius="full"
                variant="solid"
                background="#565ADD"
              >
                <TagLabel>{selectedItem.title}</TagLabel>
                <TagCloseButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClearInput();
                  }}
                />
              </Tag>
            </Flex>
          ) : (
            <Input
              ref={inputRef}
              disabled={disabled}
              sx={{
                height: "45px",
                borderRadius: "8px",
                borderColor: "lightGray",
                boxShadow: "0px 2px 4px 0px #0000000D",
                paddingTop: "2px",
                paddingRight: "40px",
              }}
              maxLength={200}
              value={searchText}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch(e);
                } else if (e.key === "Escape") {
                  setSearchText("");
                }
              }}
              onChange={(e) => {
                setSearchText(e.target.value);
                if (e.target.value.length === 0 && onReset) {
                  onReset();
                }
              }}
              onFocus={() => setIsFocused(true)}
              _focus={{
                outline: "none",
                border: `1px solid`,
                boxShadow: "0px 2px 4px 0px #0000000D",
              }}
              placeholder={`Search ${type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}s...`}
            />
          )}

          {!disabled && (
            <InputRightElement
              h="45px"
              pr="6px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={() => setAddSequenceOpen(true)}
            >
              <Icons.Add stroke="black" />
            </InputRightElement>
          )}
        </InputGroup>

        {resultsOpen && (
          <SearchDrawer
            resultRef={resultRef}
            searchResults={searchResults}
            loading={loading}
            onClick={handleClick}
          />
        )}
      </Flex>

      <AddSequenceModal
        type={type}
        pathId={pathId}
        isOpen={addSequenceOpen}
        onClose={() => setAddSequenceOpen(false)}
        onSubmit={fetchResponse}
      />
    </>
  );
};

const SearchDrawer = ({ searchResults, loading, resultRef, onClick }) => {
  const handleResultClick = (item) => {
    console.log("item", item);
    onClick(item);
  };

  return (
    <VStack
      ref={resultRef}
      pos="absolute"
      w="100%"
      maxHeight="350px"
      overflowX="hidden"
      overflowY="auto"
      align="stretch"
      mt="2"
      boxShadow="xl"
      top="40px"
      zIndex={999}
      border="1px solid #E2E8F0"
      borderRadius="md"
      bg="white"
      px="3"
      py="4"
    >
      <Flex w="100%" flexDir="column" gap="12px" justifyContent="space-between">
        <Text color="gray" mb="0">
          {searchResults?.length === 0 && !loading
            ? "No results found"
            : `${searchResults?.length} results`}
        </Text>

        {!!searchResults?.length &&
          searchResults?.map((item) => (
            <Flex
              key={item.id}
              p="5px"
              borderRadius="4px"
              cursor="pointer"
              _hover={{ backgroundColor: "gray.100" }}
              onClick={() => handleResultClick(item)}
              flexDir="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex flexDir="row" w="100%" justifyContent="space-between">
                <Flex flexDir="row" alignItems="center" gap="8px">
                  <Box
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {item?.title}
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          ))}
      </Flex>
    </VStack>
  );
};
